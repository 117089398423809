import {ESrcRights, ISecurityCtx, ISecurityCtxPointer, SEC} from 'lib/commons/security';
import {EventMgr} from "lib/commons/events";


export interface IRegPointer<ENV> {
	reg?: IReg<ENV>
}

export interface IRegsPointer<ENV> {
	regs?: IReg<ENV>[]
}

export function isRegPointer(pointer: any): pointer is IRegPointer<any> {return pointer && pointer.reg != null}

/** Registre associé à un contexte graphique racine. */
export interface IUiEnv {
	uiRoot?: HTMLElement
}

export interface IAuthenticatedEnv {
	noAuthentication?: boolean
}

/**
 * Arbre DOM "logique".
 * Pour des raisons graphiques, un noeud Html doit parfois être décroché de son contexte parent "logique",
 * notamment pour la gestion du fenêtrage.
 * Cette propriété permet de privilégier le parent "logique" au parent "physique".
 *
 * NOTE : devrait être déclaré dans DOM, mais nécessaire dans le bootstrap.
 */
export interface IDomLogical extends Node {
	logicalParent?: Element
}

/**
 * Accès en lecture aux données personnelles du user.
 */
export interface IUserDatas {

	getUserData<T extends Jsonisable>(key: string, defaultVal?: T): T
}

/**
 * Fournisseur persitant de données personelles.
 * Utilisé pour toute les natures de userDatas (globalStates, globalUserPref,
 * localStates, localUserPref).
 */
export interface IPersistUserDatas extends IUserDatas {
	/** Modification des states. Pour supprimer un state, passer undefined en value. */
	setUserDatas(datas: Dict<Jsonisable>): Promise<void>
}


/**
 * Registre central permettant de gérer :
 * <ul>
 * <li> des variables d'environnement,
 * <li> un arbre de permissions,
 * <li> les données personnelles du user
 * <li> des points d'extensions : propriétés associées à un "levelAutority" pour les mécanismes de surcharge de configuration.
 * </ul>
 *
 * Les points d'extensions (extPoints) du registre sont de différentes natures :
 * <ul>
 * <li>Pref : Simples valeurs (string, int, bolean).
 *      Syntaxe des codes de prefs : "aaa.bbb.ccc".
 * <li>List : Liste ordonnée d'objets JS. Chaque entrée dans la liste possède un code (CdEntry) et peut donc
 *      être surchargé individuellement. Chaque entrée peut aussi être une redirection dynamique vers un Svc.
 *      Syntaxe des codes de listes : "aaa:bbb:ccc".
 *      Syntaxe des codes des entrées de listes : "aaaBbbCcc".
 * <li>Svc : Service, ie un objet JS singleton (object, function ou class).
 *      Un Svc est chargé à l'init, accessible en synchrone.
 *      Syntaxe des codes de Svc en camelCase : "aaaBbbCcc".
 * <li>Skin : Fragments de styles pour être injécté dans le contexte d'un élément.
 *      Syntaxe des codes de skin: "name-du-custom-element", "name-du-custom-element/sousPartie", "context/name-du-custom-element", "codeViewOuCodeArea".
 *      Tous ces codes sont automatiquement préfixés de SKIN_PREFIX et intégrés dans les Svc, ce qui permet de les intégrer
 *      dans des Listes : addSvcToList('myList', 'entryCode', 1, 'skin:codeViewOuCodeArea')
 * <li>RolePermList : configuration des rôles et des leurs permissions.
 * </ul>
 *
 * Les points d'extension sont déclarés avec un niveau d'autorité (LevelAuthority).
 * Le LevelAuthority permet de préserver uniquement la déclaration du point d'extension avec le niveau le plus élevé.
 * L'ordre chronologique de déclaration des surcharges d'un extPoint n'est donc pas important (avant sa 1ère utilisation).
 *
 * Des sous-registres (cf SubReg) peuvent être définis permettant de surcharger / enrichir un registre.
 * Les modifications apportées au registre de ce sous-contexte restent localisées à celui-ci
 * tout en bénéficiant des caractéristiques du registre parent. Les sous-registres sont récurisifs et
 * peuvent donc constituer un arbre de sous-registres.
 *
 */
export interface IReg<ENV> extends IUserDatas, IRegPointer<ENV> {

	/** Environnement de ce registre, hérite de l'environnement de son Reg parent (chaine des prototypes). */
	readonly env: ENV

	/**
	 * Objects résolus placés ici en cache, spécifiques à chaque Reg, indépendant de son Reg parent.
	 * Exemple d'objet : AccelKeyMgr, listes mergées fréquemment utilisées, etc.
	 */
	readonly cache: Map<any, any>

	/** Registre parent. */
	readonly parentReg?: IReg<any>

	/** Pour que le reg puisse servir de contexte aux actions, areas... */
	readonly reg: this

	/**
	 * Créé une permission dans ce registre.
	 *
	 * @param parentPerm Name d'une permission.
	 * @return Un objet de type Perm.
	 */
	newPerm(permName: string, parentPerm: string, systemRights: ESrcRights): REG.Perm

	/** Retourne une permission enregistrée. */
	getPerm(permName: string): REG.Perm

	/**
	 * Evalue 1 ou N permissions dans le contexte de sécurité de ce registre ou celui passé en paramètre.
	 *
	 * @param perms Perm ou tableau de Perms.
	 * @param security Contexte de sécurité. Si null ou undefined, (this.env as ISecurityCtxPointer).securityCtx est utilisé.
	 * @param and Opérateur appliqué entre les permissions. 'or' par défaut.
	 *
	 * @return boolean true / false
	 *   (si evalPermission() retourne null, true est retourné et un warn est loggé : erreur de paramétrage).
	 */
	hasPerm(perms: string | string[], security?: ISecurityCtx, and?: boolean): boolean

	/**
	 * Evalue 1 ou N permissions en fonction des roles et de droits systèmes d'un objet métier.
	 *
	 * @param perms Perm ou tableau de Perms.
	 * @param roleNames Tableau JS de names de roles (string).
	 *    si null ou undefined : les permission sont testées sur ['~default']
	 * @param isSuperAdmin
	 * 		si null, reprend cette propriété du securityCtx courant,
	 * 		si undefined : équivaut à false
	 * @param systemRights Integer correspondant aux droits systèmes (voir Api Src.ESrcRights)
	 * @param and Opérateur appliqué entre les permissions. 'or' par défaut.
	 *
	 * @return boolean true / false
	 *   (si evalPermission() retourne null, true est retourné et un warn est loggé : erreur de paramétrage).
	 */
	hasPermission(perms: string | string[], roleNames: string[], isSuperAdmin?: boolean | null, systemRights?: ESrcRights, and?: boolean): boolean

	/**
	 * Evalue une si les droits systèmes d'un objet métier donné sont autorisés.
	 *
	 * @param perms Perm ou tableau de Perms.
	 * @param systemRights Integer correspondant aux droits systèmes (voir Api Src.ESrcRights).
	 *    Si non spécifié, c'est le ISecurityCtx par défaut du registre qui est utilisé.
	 */
	hasSystemRights(perms: string | string[], systemRights?: ESrcRights, and?: boolean): boolean

	/**
	 * Affectation dynamique des données personnelles du user par les composants.
	 * Ces données sont issues de l'analyse des lastStates, du hash de l'URL...
	 */
	setVolatileUserStates(key: string, v: Jsonisable): void

	/**
	 * Affecte le fournisseur de données personnelles persistantes.
	 * Chaque registre ne référence qu'un seul fournisseur de données personnelles persistantes.
	 * C'est la hiérarchie des registres qui constitue la pile du globalUserState et des
	 * localUserStates relatifs aux objets métiers.
	 */
	setPersistUserStates(u: IPersistUserDatas): void

	/** Retourne le 1er PersistUserDatas défini dans ce registre ou dans un registre parent. */
	getPersistUserStates(): IPersistUserDatas

	/**
	 * Affecte la valeur à une pref pour un certain niveau d'autorité.
	 * Le format des codes des prefs est de type "aaa.bbb.ccc".
	 *
	 * @param code Code de la pref à affecter.
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 * @param value Valeur à associer à la pref (string, number, boolean ou null)
	 * @return true si la valeur a été prise en compte, false si une valeur de niveau supérieur ou égal existe déjà.
	 */
	setPref(code: string, levelAuthority: number, value: REG.JPrefValue): boolean

	/**
	 * Déclare une nouvelle entrée dans une liste pour un certain niveau d'autorité.
	 *
	 * @param codeList Code la liste dans laquelle ajouter l'entrée.
	 * @param codeEntry Code de l'entrée dans la liste.
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 * @param valueEntry Valeur de l'entrée, null est autorisé et élimine l'entrée de la liste.
	 * @param sortKey Clé pour trier la liste, 0 par défaut.
	 * @return true si la valeur a été prise en compte, false si une valeur de niveau supérieur ou égal existe déjà.
	 */
	addToList(codeList: string, codeEntry: string, levelAuthority: number, valueEntry: any, sortKey?: number): boolean

	/**
	 * Déclare une nouvelle entrée sous forme de redisrection vers un svc
	 * dans une liste pour un certain niveau d'autorité.
	 *
	 * @param codeList Code la liste dans laquelle ajouter l'entrée.
	 * @param codeEntry Code de l'entrée dans la liste correspondant au code de service.
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 * @param codeSvc Code du service pointé, null est autorisé et élimine l'entrée de la liste.
	 * @param sortKey Clé pour trier la liste, 0 par défaut.
	 * @return true si la valeur a été prise en compte, false si une valeur de niveau supérieur ou égal existe déjà.
	 */
	addSvcToList(codeList: string, codeEntry: string, levelAuthority: number, codeSvc: string, sortKey?: number): boolean

	/**
	 * Déclare un service pour un certain niveau d'autorité.
	 *
	 * @param code Code du service.
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 * @param svc Objet JS correspondant à ce service.
	 * @return true si ce svc a été pris en compte, false si un svc de niveau supérieur ou égal existe déjà.
	 */
	registerSvc<S>(code: string, levelAuthority: number, svc: S): boolean

	/**
	 * Déclare un service pour un certain niveau d'autorité qui est un wrapper
	 * du Svc de niveau d'autorité inférieur.
	 * Ces Svc de type surcharge *doivent* avoir une méthode setOverridenSvc(svc) qui sera
	 * appelé à chaque récupération de ce service via getSvc(code).
	 *
	 * @param code Code du service.
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 * @param svc Objet JS correspondant à ce service.
	 * @return true si ce svc a été pris en compte, false si un svc de niveau supérieur ou égal existe déjà.
	 */
	overlaySvc(code: string, levelAuthority: number, svc: REG.IOverridingSvc): boolean

	/**
	 * Déclare un ensemble de styles.
	 * Syntaxe des codes de skin: "custom-element-name", "custom-element-name/subPart", "usageContext/custom-element-name", "codeViewOrCodeArea".
	 * Tous ces codes sont automatiquement préfixés de SKIN_PREFIX et intégrés dans les Svc, ce qui pourrait permettre de les intégrer
	 * dans des Listes via reg.addSvcToList('myList', 'entryCode', 1, 'skin:codeViewOrCodeArea')
	 */
	registerSkin(code: string, levelAuthority: number, styles: string): void

	/**
	 * Surcharge un ensemble de styles. Les nouvelles règles seront concaténées après les styles surchargés.
	 * Le niveau d'autorité spécifie l'ordre de surcharge.
	 */
	overlaySkin(code: string, levelAuthority: number, styles: string): void

	/**
	 * Ajoute ou surcharge un ou plusieurs rôles dans ce registre.
	 * Chaque propriété du role et chaque permission sont associés au niveau d'autorité levelAuthority.
	 * Il est donc possible de surcharger précisément une propriété d'un role ou un triplet role-perm-access
	 * (le tableau "erase" n'est utilisé que pour annuler un triplet d'un levelAuthority inférieur).
	 *
	 * @param levelAuthority Niveau d'autorité associée à cette affectation.
	 */
	addRolePermList(rolePermList: REG.IRoleDef | REG.IRoleDef[], levelAuthority: number): void

	copyFrom(other: IReg<any>): this

	copyTo(other: IReg<any>): void

	/**
	 * Retourne une pref de type String, Int ou Boolean.
	 *
	 * @param code Code de la pref
	 * @param defaultValue Valeur par défaut si cette pref n'existe pas
	 * @return La pref recherchée ou defaultValue, sinon undefined.
	 */
	getPref<P extends REG.JPrefValue>(code: string, defaultValue?: P): P

	/**
	 * Retourne une liste ordonnée d'objets sous forme d'un tableau JS.
	 * @return un tableau ou null si la liste n'existe pas.
	 */
	getList<T>(codeList: string): T[] | null

	/**
	 * Retourne une map d'objets sous forme d'un objet JS.
	 * @return un objet Js ou null si la liste n'existe pas.
	 */
	getListAsMap<T>(codeList: string): Dict<T> | null

	/**
	 * Retourne true si la liste existe et contient au moins une entrée.
	 */
	isListFilled(codeList: string): boolean

	/**
	 * Retourne une liste ordonnée d'objets (sous forme d'un tableau JS) issue d'une fusion de plusieurs listes.
	 * Lorsque plusieurs listes définissent la même entrée (cdEntry identique), c'est le niveau d'autorité le plus
	 * élevé qui l'emporte et l'entrée de la 1ère liste si le niveau d'autorité est identique.
	 * @param codeLists Tableau de codes de listes à fusionner.
	 * @return un tableau ordonné des entrées (éventuellement vide, ne retourne jamais null).
	 */
	mergeLists<T>(...codeLists: string[]): T[]

	/**
	 * Retourne une map d'objets (sous forme d'un objet JS) issue d'une fusion de plusieurs listes.
	 * Lorsque plusieurs listes définissent la même entrée (cdEntry identique), c'est le niveau d'autorité le plus
	 * élevé qui l'emporte et l'entrée de la 1ère liste si le niveau d'autorité est identique.
	 * @param cdLists un tableau de codes de listes à fusionner.
	 * @return un objet de type dictionnaire (éventuellement vide, ne retourne jamais null).
	 */
	mergeListsAsMap<T>(...cdLists: string[]): Dict<T>

	/**
	 * Retourne un service (Objet Js singletion dans le contexte de ce registre).
	 *
	 * @param code Code du service.
	 * @return Le svc ou null si il a été surchargé en 'null', ifNone ou undefined si svc non trouvé.
	 */
	getSvc<T>(code: string, ifNone?: T): T | null | undefined

	installSkin(code: string, from: Node): HTMLStyleElement

	createSkin(code: string): HTMLStyleElement

	/**
	 * Efface tous les extPoints et perms configurés pour re-init.
	 * Exemple : reload du wsp et de son wspMetaUi qui vont redéfinir les extPoints (de ce SubReg).
	 * ATTENTION : les subReg héritant de celui-ci sont obsolets et ne doivent plus être utilisés.
	 */
	resetExtPoints(): void

	/**
	 * Fermeture de ce registre. Désabonnement à tous les listeners externes et libération des ressources.
	 * Une fois le registre est inutilisable, tous les extPoints env et caches sont supprimés pour aider le GC.
	 * ATTENTION : les subReg héritant de celui-ci sont obsolets et ne doivent plus être utilisés.
	 */
	close(): void

	isClosed: boolean,

	/** Callback(s) à appeler à l'appel de close() de ce registre. */
	onRegClose: EventMgr<() => void>

	/**
	 * Retourne une définition json de ce registre. Pour debug.
	 */
	getRegDef(): REG.JRegDef
}

export namespace REG {

	/**
	 * Récupère le reg à partir d'un objet contexte qui pourrait être un IRegPointer<ENV>
	 * ou un Node | IDomLogical dont ce noeud ou un de ces ancêtres logiques peut implémenter IRegPointer<ENV>.
	 * A défaut retourne REG.reg.
	 */
	export function getReg<ENV>(ctx: any): IReg<ENV> {
		if (ctx instanceof Node) return findRegFromDom(ctx) || reg;
		return ctx ? (ctx as IRegPointer<any>).reg || reg : reg;
	}

	/**
	 * Recherche un registre dans le contexte d'un noeud DOM, mais privilégie la recherche
	 * dans un objet contexte si disponible.
	 */
	export function findReg<ENV>(from: Node | IDomLogical, ctx?: IRegPointer<any>): IReg<ENV> {
		if (ctx) {
			if (ctx.reg) return ctx.reg;
			if (ctx instanceof Node) return findRegFromDom(ctx) || findRegFromDom(from) || reg;
		}
		return findRegFromDom(from) || reg;
	}

	/** */
	function findRegFromDom<ENV>(from: Node | IDomLogical): IReg<ENV> {
		let n = from;
		while (n) {
			if (isRegPointer(n)) return n.reg;
			n = 'logicalParent' in n ? n.logicalParent
				: (n as Element | Text).assignedSlot
				|| n.parentElement
				|| (n.parentNode instanceof ShadowRoot ? n.parentNode.host : null);
		}
		return null;
	}

	/** Recherche la librairie de déclaration d'une perm scenari donnée (pour load) */
	export async function findScPermLibPath(permCode: string): Promise<string | undefined> {
		let {SC_PERMS} = await import("lib/commons/utils/scPerms.js");
		return (SC_PERMS as any)[permCode];
	}

	/** Création d'un SubReg à partir du registre graphique contextuel à un node combiné à un registre d'un objet métier. */
	export function createSubReg<ENV>(fromUi: Node | IReg<any>, businessObject?: IRegPointer<any> | IReg<any>): IReg<ENV> {
		const subReg = new SubReg<ENV>(fromUi instanceof REG.Reg ? fromUi : REG.findReg(fromUi as Node) as Reg<any>);
		if (businessObject instanceof REG.Reg) subReg.copyFrom(businessObject);
		else if (isRegPointer(businessObject)) subReg.copyFrom(businessObject.reg);
		return subReg;
	}

	/** Création d'un SubReg en mixant deux reg (et exploitant le typage automatique de TS) */
	export function createSubRegMixed<EUI, EBIZ>(uiReg: IReg<EUI>, bizReg: IReg<EBIZ>): IReg<EUI & EBIZ> {
		return new SubReg<EUI & EBIZ>(uiReg as Reg<EUI>).copyFrom(bizReg);
	}

	export function removeSkin(skin: string, from: Node & ParentNode) {
		while (from.parentNode) from = from.parentNode;
		for (let ch = from.firstElementChild; ch; ch = ch.nextElementSibling) {
			if (ch.localName === "style" && ch.getAttribute("data-code") === skin) {
				ch.remove();
				return;
			}
		}
	}

	export class Reg<ENV> implements IReg<ENV> {

		env: ENV;

		protected _cache: Map<any, any>;
		get cache(): Map<any, any> {return this._cache || (this._cache = new Map())}

		parentReg?: Reg<any>;

		get reg(): this {return this}

		/** Map des permissions du registre. */
		protected _perms: Dict<Perm>;

		/**
		 * Données personnelles persistantes. Chaque registre ne possède qu'un seul persitant userState.
		 * La hiérarchie des registres constitue la pile du globalUserState et des localUserStates.
		 */
		protected _persistUS: IPersistUserDatas;

		/** Données personnelles du user modifiées par les composants (lastStates, url hash...). */
		protected _volatileUS: Dict<Jsonisable>;

		//Structures internes de type extPoints
		protected _prefLevels: { [code: string]: number } = Object.create(null);
		protected _prefs: { [code: string]: JPrefValue } = Object.create(null);
		protected _lists: { [codeList: string]: IList } = Object.create(null);
		protected _svcs: { [codeSvc: string]: ISvcDef } = Object.create(null);
		protected _roles: { [codeRole: string]: IRoleStruct } = Object.create(null);

		constructor(fromSubReg?: boolean) {
			if (!fromSubReg) {
				/* Role ~default
				 * 	> porte le paramétrage applicatif via le systeme de droits
				 *  > role utilisé qd le contexte ne gère pas de droits (chain, home, ...)
				 */
				this.addRolePermList({role: SEC.DEFAULT_ROLE_NAME, title: SEC.DEFAULT_ROLE_NAME, priority: 0, allow: ["DO", "DATA"]}, 1);
				this.addRolePermList({role: SEC.FALLBACK_ROLE_NAME, title: SEC.FALLBACK_ROLE_NAME, superRole: SEC.DEFAULT_ROLE_NAME, priority: 0, allow: ["use"], deny: ["DO", "DATA"]}, 1);

				this.env = Object.create(null);
				this._perms = Object.create(null);
				this._volatileUS = Object.create(null);
			}
		}

		get onRegClose(): EventMgr<() => void> {return this._onRegClose || (this._onRegClose = new EventMgr())}

		protected _onRegClose: EventMgr<() => void>;

		close() {
			//clos userData et autres ICloseable inscrits dans un listener 'onRegClose'.
			if (this._onRegClose) this._onRegClose.emitCatched();
			this._onRegClose = null; //Marque ce reg clos.
			//help GC -- NON provoque trop d'erreures liées à des traitements post-cloture
			//this.env = this.cache = this._onRegClose = this._prefLevels = this._prefs = this._lists = this._svcs = this._roles = this._perms = this._volatileUS = undefined;
		}

		get isClosed() {return this._onRegClose === null}

		resetExtPoints() {
			this._prefLevels = Object.create(null);
			this._prefs = Object.create(null);
			this._lists = Object.create(null);
			this._svcs = Object.create(null);
			this._roles = Object.create(null);
			this._perms = Object.create(null);
			this._volatileUS = Object.create(null);
		}

		newPerm(permName: string, parentPerm: string, systemRights: ESrcRights): Perm {
			let perm = this._perms[permName];
			if (perm) return perm;
			const parent = this._perms[parentPerm];
			if (parentPerm && !parent) console.warn("Parent permission '" + parentPerm + "' of '" + permName + "' not found in " + this);
			perm = new Perm(permName, parent, systemRights);
			this._perms[permName] = perm;
			return perm;
		}

		getPerm(permName: string): Perm {
			return this._perms[permName];
		}

		hasPerm(perms: string | string[], security?: ISecurityCtx, and?: boolean): boolean {
			if (!security) security = (this.env as ISecurityCtxPointer).securityCtx;
			if (!security) return false; //pas de contexte de sécurité initialisé, on désactive toutes les perms.
			return this.hasPermission(perms, security.srcRoles, security.isSuperAdmin, security.srcRi, and);
		}

		hasPermission(perms: string | string[], roleNames: string[], isSuperAdmin?: boolean | null, systemRights?: ESrcRights, and?: boolean): boolean {
			if (isSuperAdmin === null) {
				const security = (this.env as ISecurityCtxPointer)?.securityCtx;
				isSuperAdmin = security ? security.isSuperAdmin : false;
			}
			if (isSuperAdmin) return this.hasSystemRights(perms, systemRights, and) !== false;
			const rolesNames = roleNames || ((this.env as IAuthenticatedEnv).noAuthentication ? SEC.DEFAULT_ROLES : SEC.FALLBACK_ROLES);
			if (Array.isArray(perms)) {
				const l = perms.length;
				if (l === 0) return true;
				if (and) {
					for (let i = 0; i < l; i++) {
						const p = this.evalPermission(perms[i], roleNames, systemRights);
						if (p === false) return false;
						if (p == null) console.warn("Permission '" + perms[i] + "' not declared for roles : " + rolesNames);
					}
					return true;
				} else {
					for (let i = 0; i < l; i++) {
						const p = this.evalPermission(perms[i], roleNames, systemRights);
						if (p === true) return true;
						if (p == null) console.warn("Permission '" + perms[i] + "' not declared for roles : " + rolesNames);
					}
					return false;
				}
			}
			const p = this.evalPermission(perms, rolesNames, systemRights);
			if (p == null) console.warn("Permission '" + perms + "' not declared for roles : " + rolesNames);
			return p !== false;
		}

		hasSystemRights(perms: string | string[], systemRights?: ESrcRights, and?: boolean): boolean {
			if (systemRights === undefined) {
				const security = (this.env as ISecurityCtxPointer).securityCtx;
				if (!security) return true;
				systemRights = security.srcRi;
			}
			if (Array.isArray(perms)) {
				const l = perms.length;
				if (l === 0) return true;
				if (and) {
					for (let i = 0; i < l; i++) {
						const p = this.evalSystemRights(perms[i], systemRights);
						if (p === false) return false;
						if (p == null) console.warn("Permission '" + perms[i] + "' not declared");
					}
					return true;
				} else {
					for (let i = 0; i < l; i++) {
						const p = this.evalSystemRights(perms[i], systemRights);
						if (p === true) return true;
						if (p == null) console.warn("Permission '" + perms[i] + "' not declared");
					}
					return false;
				}
			}
			return this.evalSystemRights(perms, systemRights) !== false;
		}

		//############################ UserDatas #############################

		setVolatileUserStates(key: string, v: Jsonisable) {
			this._volatileUS[key] = v;
		}

		setPersistUserStates(u: IPersistUserDatas) {
			this._persistUS = u;
		}

		getPersistUserStates(): IPersistUserDatas {return this._persistUS}

		getUserData<T extends Jsonisable>(key: string, defaultVal?: T): T {
			const level = this.getPrefLevel(key);
			if (level >= LEVELAUTH_USER) return this.getPref(key);
			let v = this._volatileUS[key];
			if (v !== undefined) return v;
			v = this._getPersistUserData(key);
			if (v !== undefined) return v;
			if (level !== -Infinity) return this.getPref(key);
			return defaultVal;
		}

		/* protected */
		_getPersistUserData(key: string): Jsonisable | undefined {
			if (this._persistUS) return this._persistUS.getUserData(key);
		}

		//############################ Affectations des extPoints #############################

		setPref(code: string, levelAuthority: number, value: JPrefValue): boolean {
			const currentLevel = this._prefLevels[code];
			if (currentLevel == null || currentLevel < levelAuthority) {
				this._prefLevels[code] = levelAuthority;
				this._prefs[code] = value;
				return true;
			}
			return false;
		}

		addToList(codeList: string, codeEntry: string, levelAuthority: number, valueEntry: any, sortKey = 0): boolean {
			let list = this._lists[codeList];
			if (!list) {
				list = this._lists[codeList] = Object.create(null);
			}
			const entry = list[codeEntry];
			if (!entry || entry.level < levelAuthority) {
				list[codeEntry] = {
					level: levelAuthority,
					code: codeEntry,
					value: valueEntry,
					sortKey: sortKey
				};
				return true;
			}
			return false;
		}

		addSvcToList(codeList: string, codeEntry: string, levelAuthority: number, codeSvc: string, sortKey = 0): boolean {
			let list = this._lists[codeList];
			if (!list) {
				list = this._lists[codeList] = Object.create(null);
			}
			const entry = list[codeEntry];
			if (!entry || entry.level < levelAuthority) {
				list[codeEntry] = {
					level: levelAuthority,
					code: codeEntry,
					codeSvc: codeSvc,
					value: codeSvc != null ? REDIRECT_TO_SVC : null,
					sortKey: sortKey
				};
				return true;
			}
			return false;
		}

		registerSvc<S>(code: string, levelAuthority: number, svc: S): boolean {
			const svcDef = this._svcs[code];
			if (!svcDef || svcDef.level < levelAuthority) {
				this._svcs[code] = {
					svc: svc,
					level: levelAuthority
				};
				return true;
			} else if ("override" in svcDef) {
				return this._insertSvcInOverrideChain(svcDef, svc, levelAuthority);
			}
			return false;
		}

		overlaySvc(code: string, levelAuthority: number, svc: IOverridingSvc): boolean {
			const svcDef = this._svcs[code];
			if (!svcDef || svcDef.level < levelAuthority) {
				this._svcs[code] = {
					svc: svc,
					level: levelAuthority,
					override: svcDef
				};
				return true;
			} else if ("override" in svcDef) {
				return this._insertSvcInOverrideChain(svcDef, svc, levelAuthority, true);
			}
			return false;
		}

		registerSkin(code: string, levelAuthority: number, styles: string) {
			this.registerSvc(SKIN_PREFIX + code, levelAuthority, new SkinHolder(code, styles));
		}

		overlaySkin(code: string, levelAuthority: number, styles: string) {
			this.overlaySvc(SKIN_PREFIX + code, levelAuthority, new SkinHolder(code, styles));
		}

		addRolePermList(rolePermList: IRoleDef | IRoleDef[], levelAuthority: number): void {
			if (Array.isArray(rolePermList)) {
				for (const i in rolePermList) this.addRolePermList(rolePermList[i], levelAuthority);
				return;
			}
			//log.info("will Add role::::::::::::\n"+JSON.stringify(rolePermList));
			if (!rolePermList.role) return;
			let r = this._roles[rolePermList.role];
			if (!r) r = this._roles[rolePermList.role] = {role: rolePermList.role, perms: Object.create(null)};
			if ("title" in rolePermList) {
				if (!r.title || r.title.level < levelAuthority) r.title = {level: levelAuthority, value: rolePermList.title}
			}
			if ("priority" in rolePermList) {
				if (!r.priority || r.priority.level < levelAuthority) r.priority = {level: levelAuthority, value: rolePermList.priority}
			}
			if ("superRole" in rolePermList) {
				if (!r.superRole || r.superRole.level < levelAuthority) r.superRole = {level: levelAuthority, value: rolePermList.superRole}
			}
			if ("setPerm" in rolePermList) {
				if (!r.setPerm || r.setPerm.level < levelAuthority) r.setPerm = {level: levelAuthority, value: rolePermList.setPerm}
			}
			if (rolePermList.deny) for (let i = 0, l = rolePermList.deny.length; i < l; i++) {
				const permName = rolePermList.deny[i];
				const pre = r.perms[permName];
				if (!pre || pre.level < levelAuthority) r.perms[permName] = {level: levelAuthority, value: false}
			}
			if (rolePermList.allow) for (let i = 0, l = rolePermList.allow.length; i < l; i++) {
				const permName = rolePermList.allow[i];
				const pre = r.perms[permName];
				if (!pre || pre.level < levelAuthority) r.perms[permName] = {level: levelAuthority, value: true}
			}
			if (rolePermList.erase) for (let i = 0, l = rolePermList.erase.length; i < l; i++) {
				const permName = rolePermList.erase[i];
				const pre = r.perms[permName];
				if (!pre || pre.level < levelAuthority) r.perms[permName] = {level: levelAuthority, value: null}
			}
			//log.info("Role:::\n"+JSON.stringify(r));
		}


		copyFrom(other: IReg<any>): this {
			if (other) other.copyTo(this);
			return this;
		}

		copyTo(other: Reg<any>) {
			Object.assign(other.env, this.env);
			Object.assign(other._perms, this._perms);
			for (const nm of Object.getOwnPropertyNames(this._prefs)) {
				other.setPref(nm, this._prefLevels[nm], this._prefs[nm]);
			}
			for (const nm of Object.getOwnPropertyNames(this._svcs)) {
				const svc = this._svcs[nm];
				if ('override' in svc) {
					other.overlaySvc(nm, svc.level, svc.svc);
				} else {
					other.registerSvc(nm, svc.level, svc.svc);
				}
			}
			for (const nm of Object.getOwnPropertyNames(this._lists)) {
				const lst = this._lists[nm];
				for (const entry of Object.values(lst)) {
					if (entry.value === REDIRECT_TO_SVC) other.addSvcToList(nm, entry.code, entry.level, entry.codeSvc, entry.sortKey);
					else other.addToList(nm, entry.code, entry.level, entry.value, entry.sortKey);
				}
			}
			for (const nm of Object.getOwnPropertyNames(this._roles)) {
				const role: IRoleStruct = this._roles[nm];
				if ("title" in role) other.addRolePermList({role: nm, title: role.title.value}, role.title.level);
				if ("description" in role) other.addRolePermList({role: nm, description: role.description.value}, role.description.level);
				if ("priority" in role) other.addRolePermList({role: nm, priority: role.priority.value}, role.priority.level);
				if ("sortKey" in role) other.addRolePermList({role: nm, sortKey: role.sortKey.value}, role.sortKey.level);
				if ("superRole" in role) other.addRolePermList({role: nm, superRole: role.superRole.value}, role.superRole.level);
				if ("setPerm" in role) other.addRolePermList({role: nm, setPerm: role.setPerm.value}, role.setPerm.level);
				for (const [perm, val] of Object.entries(role.perms)) {
					if (val.value === true) {
						other.addRolePermList({role: nm, allow: [perm]}, val.level);
					} else if (val.value === false) {
						other.addRolePermList({role: nm, deny: [perm]}, val.level);
					} else {
						other.addRolePermList({role: nm, erase: [perm]}, val.level);
					}
				}
			}
		}

		//############################ Lecture des extPoints #############################

		getPref<P extends REG.JPrefValue>(code: string, defaultValue?: P): P {
			return (code in this._prefs) ? this._prefs[code] as P : defaultValue;
		}

		getList<T>(codeList: string): T[] {
			const list = this._getListEntries(codeList);
			if (!list) return null;

			const result: any[] = [];
			for (const code in list) {
				if (list[code].value != null) result.push(list[code]);
			}
			result.sort(sortEntries);
			let i, k;
			for (i = 0, k = 0; i < result.length; i++) {
				let val = result[i].value;
				if (val === REDIRECT_TO_SVC) {
					val = this.getSvc(result[i].codeSvc);
					if (val) result[k++] = val;
				} else {
					result[k++] = val;
				}
			}
			if (k < i) result.splice(k);
			return result;
		}

		getListAsMap<T>(codeList: string): Dict<T> {
			const list = this._getListEntries(codeList);
			if (!list) return null;

			const result: Dict<T> = Object.create(null);
			for (const code in list) {
				const entry = list[code];
				if (entry.value != null) result[entry.code] = entry.value === REDIRECT_TO_SVC ? this.getSvc(entry.codeSvc) : entry.value;
			}
			return result;
		}

		isListFilled(codeList: string): boolean {
			const list = this._getListEntries(codeList);
			if (!list) return false;
			for (const code in list) {
				if (list[code].value != null) return true;
			}
			return false;
		}

		mergeLists<T>(...cdLists: string[]): T[] {
			if (cdLists.length === 1) return this.getList(cdLists[0]) || [];
			const mergedList: Dict<IListEntry> = Object.create(null);
			for (let i = 0; i < cdLists.length; i++) {
				const list = this._getListEntries(cdLists[i]);
				if (list) {
					for (const code in list) {
						const entry = list[code];
						const mergedEntry = mergedList[entry.code];
						if (!mergedEntry || mergedEntry.level < entry.level) {
							mergedList[entry.code] = entry;
						}
					}
				}
			}

			const result: any[] = [];
			for (const code in mergedList) {
				const entry = mergedList[code];
				if (entry.value != null) result.push(entry);
			}
			result.sort(sortEntries);
			let i, k;
			for (i = 0, k = 0; i < result.length; i++) {
				let val = result[i].value;
				if (val === REDIRECT_TO_SVC) {
					val = this.getSvc(result[i].codeSvc);
					if (val) result[k++] = val;
				} else {
					result[k++] = val;
				}
			}
			if (k < i) result.splice(k);

			return result;
		}

		mergeListsAsMap<T>(...cdLists: string[]): Dict<T> {
			if (cdLists.length === 1) return this.getListAsMap(cdLists[0]) || Object.create(null);
			const mergedList: IList = Object.create(null);
			for (let i = 0; i < cdLists.length; i++) {
				const list = this._getListEntries(cdLists[i]);
				if (list) {
					for (const code in list) {
						const entry = list[code];
						const mergedEntry = mergedList[entry.code];
						if (!mergedEntry || mergedEntry.level < entry.level) {
							mergedList[entry.code] = entry;
						}
					}
				}
			}
			const result: Dict<T> = Object.create(null);
			for (const code in mergedList) {
				const entry = mergedList[code];
				if (entry.value != null) result[entry.code] = entry.value === REDIRECT_TO_SVC ? this.getSvc(entry.codeSvc) : entry.value;
			}
			return result;
		}

		getSvc<T>(code: string, ifNone?: T): T | null | undefined {
			try {
				const svcDef = this._svcs[code];
				if (!svcDef) return ifNone;
				if (svcDef.svc == null) return svcDef.svc; //null ou undefined
				if ("override" in svcDef) this._setOverridenSvc(code, svcDef);
				return svcDef.svc;
			} catch (e) {
				console.error("Load lib " + code + " failed::", e);
				return ifNone;
			}
		}

		installSkin(code: string, from: Node = document): HTMLStyleElement {
			while (from.parentNode) from = from.parentNode;
			const skin = this.getSvc(SKIN_PREFIX + code);
			return skin instanceof SkinHolder ? skin.installSkin(from as Document | ShadowRoot) : null;
		}

		createSkin(code: string): HTMLStyleElement {
			const skin = this.getSvc(SKIN_PREFIX + code);
			return skin instanceof SkinHolder ? skin.createStyleElt() : null;
		}

		/**
		 * Evalue une ou plusieurs permissions en fonction de roles et de droits systèmes associés à un objet.
		 *
		 * @param perm Objet ou tableau d'objet de type Perm issu de module SECURITY.
		 * @param roleNames Tableau de names de roles (string).
		 * @param systemRights Integer correspondant aux droits systèmes (voir Api Src)
		 *
		 * @return boolean true, false ou null (ce qui correspond normalement à une erreur de paramétrage).
		 */
		evalPermission(perm: string, roleNames: string[], systemRights?: ESrcRights): boolean | null {
			const p: Perm = this._perms[perm];
			if (!p) return null;

			//System rights
			if (systemRights != null) {
				//log.info("evalPermission:::"+pPerms+" for roles:::"+pRoleNames);
				let permRi = p;
				while (permRi) {
					if (permRi.systemRights > 0 && ~(~permRi.systemRights | systemRights) != 0) return false;
					permRi = permRi.parent;
				}
			}

			if (!roleNames || roleNames.length === 0) return false;
			if (roleNames.length > 1) {
				roleNames.sort((r1, r2) => {
					return (this.getRoleProperty(r2, "priority") as number || 0) - (this.getRoleProperty(r1, "priority") as number || 0);
				});
			}
			//log.info("evalPermission:::"+perms+" for roles:::"+roleNames);
			for (let i = 0, l = roleNames.length; i < l; i++) {
				let permHier = p;
				while (permHier) {
					let r = roleNames[i];
					while (r) {
						const access = this.getRolePermAccess(r, permHier.name);
						//log.info("evalPermission:::"+permHier+" for role:::"+r+" result:::"+access+" --\n"+JSON.stringify(this.roles[r]));
						if (access == true) return true;
						if (access == false) return false;
						r = this.getRoleProperty(r, "superRole") as "string";
					}
					permHier = permHier.parent;
				}
			}
			return null;
		}

		evalSystemRights(perm: string, systemRights: ESrcRights): boolean | null {
			const p = this._perms[perm];
			if (!p) return null;
			if (systemRights == null) return true;
			//log.info("evalPermission:::"+pPerms+" for roles:::"+pRoleNames);
			let permRi = p;
			while (permRi) {
				if (permRi.systemRights > 0 && ~(~permRi.systemRights | systemRights) != 0) return false;
				permRi = permRi.parent;
			}
			return true;
		}

		//##################### Usages avancés / internes ######################

		/**
		 * Retourne le niveau d'autorité actuel d'une pref.
		 *
		 * @return Number.NEGATIVE_INFINITY si indifini.
		 */
		getPrefLevel(code: string): number {
			const lev = this._prefLevels[code];
			return lev != undefined ? lev : Number.NEGATIVE_INFINITY;
		}

		/**
		 * Retourne le niveau d'autorité actuel d'un service.
		 *
		 * @return Number.NEGATIVE_INFINITY si indifini.
		 */
		getSvcLevel(code: string): number {
			const svc = this._svcs[code];
			return svc ? svc.level : Number.NEGATIVE_INFINITY;
		}

		/**
		 * Retourne le niveau d'autorité actuel d'une entrée d'une liste.
		 *
		 * @return Number.NEGATIVE_INFINITY si indifini.
		 */
		getEntryListLevel(codeList: string, codeEntry: string): number {
			const list = this._lists[codeList];
			if (!list) return Number.NEGATIVE_INFINITY;
			const entry = list[codeEntry];
			if (!entry) return Number.NEGATIVE_INFINITY;
			return entry.level;
		}

		/**
		 * Usage avancé / interne
		 * Teste s'il existe des rôles
		 *
		 * @return true si au moins un role est défini
		 */
		hasRoles(): boolean {
			for (const i in this._roles) return true;
			return false;
		}

		/**
		 * Usage avancé / interne
		 * Retourne la liste des rôles définies
		 *
		 * @return tableau des noms des rôles.
		 */
		getRoleNames(): string[] {
			return Object.keys(this._roles);
		}

		/**
		 * Usage avancé / interne.
		 * Retourne une propriété (title, priority, superRole...) d'un role.
		 *
		 * @return undefined si cette propriété ou ce role n'existe pas.
		 */
		getRoleProperty(role: string, propName: keyof IRoleValues): string | number | boolean | undefined {
			const r = this._roles[role];
			if (!r) return undefined;
			const p = r[propName];
			if (!p) return undefined;
			return p.value;
		}

		/**
		 * Usage avancé / interne
		 * Retourne la liste des permissions définies pour ce role
		 *
		 * @return tableau des noms des permissions ou undefined si ce role n'est pas trouvé.
		 */
		getRolePermNames(role: string): string[] | undefined {
			const r = this._roles[role];
			if (!r) return undefined;
			return r.perms ? Object.keys(r.perms) : [];
		}

		/**
		 * Usage avancé / interne.
		 * Retourne un accès pour un couple role / permission.
		 *
		 * @return true, false, null (erase) ou undefined si ce couple n'exite pas.
		 */
		getRolePermAccess(roleName: string, permName: string): boolean | null | undefined {
			const r = this._roles[roleName];
			if (!r) return undefined;
			const p = r.perms[permName];
			if (!p) return undefined;
			return p.value;
		}

		/**
		 * Usage avancé / interne. Retourne le level une propriété (title, priority, extend, alternateGrou, visible, ...) d'un role.
		 *
		 * @return Number.NEGATIVE_INFINITY si cette propriété ou ce role n'existe pas.
		 */
		getRolePropertyLevel(roleName: string, propName: keyof IRoleValues): number {
			const r = this._roles[roleName];
			if (!r) return Number.NEGATIVE_INFINITY;
			const p = r[propName];
			if (!p) return Number.NEGATIVE_INFINITY;
			return p.level;
		}

		/**
		 * Usage avancé / interne. Retourne le level d'une permission associée à un role.
		 *
		 * @return Number.NEGATIVE_INFINITY si ce couple role-permission n'est pas définit.
		 */
		getRolePermLevel(roleName: string, permName: string): number {
			const r = this._roles[roleName];
			if (!r) return Number.NEGATIVE_INFINITY;
			const p = r.perms[permName];
			if (!p) return Number.NEGATIVE_INFINITY;
			return p.level;
		}

		/**
		 * Retourne un tableau avec toutes les prefs.
		 * TODO @param pWithInherited intègre les valeurs des registres parents.
		 */
		dumpPrefs(): { cd: string, value: string | number | Array<Jsonisable> | boolean, level: number }[] {
			const result = [];
			for (const code in this._prefs) {
				result.push({cd: code, value: this._prefs[code], level: this._prefLevels[code]});
			}
			return result;
		}

		/**
		 * Retourne un tableau avec tous les Svc.
		 * TODO @param pWithInherited intègre les valeurs des registres parents.
		 */
		dumpSvcs(): { cd: string, value: any, level: number }[] {
			const result = [];
			for (const code in this._svcs) {
				const svc = this._svcs[code];
				result.push({cd: code, value: svc.svc, level: svc.level});
			}
			return result;
		}

		/**
		 * Retourne un tableau avec toutes les List.
		 * TODO @param pWithInherited intègre les valeurs des registres parents.
		 */
		dumpLists(): { cd: string, cdEntry: string, value: any, sortKey: number, level: number }[] {
			const result = [];
			for (const code in this._lists) {
				const list: IList = this._lists[code]; //this.xGetListEntries(codeList);
				for (const cdEntry in list) {
					const entry: IListEntry = list[cdEntry];
					const obj = {
						cd: code,
						cdEntry: cdEntry,
						value: entry.value,
						sortKey: entry.sortKey,
						level: entry.level
					};
					if (obj.value === REDIRECT_TO_SVC) obj.value = this.getSvc(entry.codeSvc);
					result.push(obj);
				}
			}
			return result;
		}

		/**
		 * Retourne un tableau des roles avac leurs permissions associées :
		 *
		 * TODO @param withInherited intègre les valeurs des registres parents.
		 */
		dumpRolePermList(): IRoleDump[] {
			const result = [];
			for (const roleName in this._roles) {
				const role: IRoleStruct = this._roles[roleName];
				const newRole: IRoleDump = {role: roleName};
				if (role.title) {
					newRole.title = role.title.value;
					newRole.titleLevel = role.title.level;
				}
				if (role.priority) {
					newRole.priority = role.priority.value;
					newRole.priorityLevel = role.priority.level;
				}
				if (role.superRole) {
					newRole.superRole = role.superRole.value;
					newRole.superRoleLevel = role.superRole.level;
				}
				if (role.setPerm) {
					newRole.setPerm = role.setPerm.value;
					newRole.setPermLevel = role.setPerm.level;
				}
				if (role.sortKey) {
					newRole.sortKey = role.sortKey.value;
					newRole.sortKeyLevel = role.sortKey.level;
				}
				if (role.description) {
					newRole.description = role.description.value;
					newRole.descriptionLevel = role.description.level;
				}
				for (const [nm, val] of Object.entries(role.perms)) {
					if (val.value === true) {
						if (!newRole.allow) newRole.allow = [nm];
						else newRole.allow.push(nm);
					} else if (val.value === false) {
						if (!newRole.deny) newRole.deny = [nm];
						else newRole.deny.push(nm);
					} else {
						if (!newRole.erase) newRole.erase = [nm];
						else newRole.erase.push(nm);
					}
				}
				result.push(newRole);
			}
			return result;
		}


		/** Construit un arbre des perms combinant les perms avec tous les registres ancêtres.*/
		getPermsTree(rootPermName: string): IPermNode {
			const perm = this._perms[rootPermName || "DO"];
			if (perm) return null;
			const permNode: IPermNode = {
				name: perm.name,
				systemRights: perm.systemRights
			};
			for (const key in this._perms) {
				const parent = this._perms[key].parent;
				if (parent && parent === perm) {
					if (!permNode.children) permNode.children = [];
					permNode.children.push(this.getPermsTree(key));
				}
			}
			return permNode;
		}


		/**
		 * Isolement du getter d'une liste pour permettre la fusion dans SubReg.
		 */
		_getListEntries(codeList: string): IList {
			return this._lists[codeList];
		}

		/** Utilié par l'enregistrement de Svc */
		protected _insertSvcInOverrideChain(parent: ISvcDef, svc: any, levelAuthority: number, isOverride?: boolean): boolean {
			if (!parent.override || parent.override.level < levelAuthority) {
				if (isOverride) {
					parent.override = {
						svc: svc,
						level: levelAuthority,
						override: parent.override
					}
				} else {
					parent.override = {
						svc: svc,
						level: levelAuthority
					}
				}
				return true;
			} else if ("override" in parent.override) {
				return this._insertSvcInOverrideChain(parent.override, svc, levelAuthority);
			}
			return false;
		}

		/** Affectation du svc surchargé au svc courant. */

		/* protected */
		_setOverridenSvc(code: string, svcDef: ISvcDef) {
			const subSvcDef = svcDef.override;
			if (subSvcDef && subSvcDef.svc) {
				this._setOverridenSvc(code, subSvcDef); //chainage des override
				svcDef.svc.setOverridenSvc(subSvcDef.svc);
			}
		}

		/**
		 * Retourne une définition json de ce registre. Pour debug
		 */
		getRegDef(): JRegDef {
			return this.env ? {envKeys: Object.getOwnPropertyNames(this.env)} : {closed: true};
		}

		toString() {return JSON.stringify(this.getRegDef())}
	}


	/**
	 * SubReg : registre qui surcharge un registre parent.
	 *
	 * XXX Attention, avec cette impl, une modification ultérieure de l'extPoints parent
	 * (avec un niveau d'autorité supérieur) sur un point d'extension surchargé dans
	 * le SubReg ne sera pas pris en compte. Est-ce un pb ou une feature ? discutable,
	 * pas forcément abérrant...
	 *
	 */
	class SubReg<ENV> extends Reg<ENV> {

		constructor(parentReg: Reg<any>) {
			super(true);
			this.parentReg = parentReg;
			this.env = Object.create(this.parentReg.env as any) as ENV;
			this._perms = Object.create((this.parentReg as any)._perms);
			this._volatileUS = Object.create((this.parentReg as any)._volatileUS);
		}

		get isClosed() {return this._onRegClose === null || this.parentReg.isClosed}

		resetExtPoints() {
			this._prefLevels = Object.create(null);
			this._prefs = Object.create(null);
			this._lists = Object.create(null);
			this._svcs = Object.create(null);
			this._roles = Object.create(null);
			this._perms = Object.create((this.parentReg as any)._perms);
			this._volatileUS = Object.create((this.parentReg as any)._volatileUS);
		}

		getPersistUserStates(): IPersistUserDatas {
			if (this._persistUS) return this._persistUS;
			return this.parentReg.getPersistUserStates();
		}

		setPref(code: string, levelAuthority: number, value: JPrefValue): boolean {
			if (this.parentReg.getPrefLevel(code) >= levelAuthority) return false;
			return Reg.prototype.setPref.apply(this, arguments); //call super
		}

		addToList(codeList: string, codeEntry: string, levelAuthority: number, valueEntry: any, sortKey = 0): boolean {
			if (this.parentReg.getEntryListLevel(codeList, codeEntry) >= levelAuthority) return false;
			return Reg.prototype.addToList.apply(this, arguments); //call super
		}

		addSvcToList(codeList: string, codeEntry: string, levelAuthority: number, codeSvc: string, sortKey = 0): boolean {
			if (this.parentReg.getEntryListLevel(codeList, codeEntry) >= levelAuthority) return false;
			return Reg.prototype.addSvcToList.apply(this, arguments); //call super
		}

		registerSvc<S>(code: string, levelAuthority: number, svc: S): boolean {
			if (this.parentReg.getSvcLevel(code) >= levelAuthority) return false;
			return Reg.prototype.registerSvc.apply(this, arguments); //call super
		}

		overlaySvc(code: string, levelAuthority: number, svc: IOverridingSvc): boolean {
			if (this.parentReg.getSvcLevel(code) >= levelAuthority) return false;
			return Reg.prototype.overlaySvc.apply(this, arguments); //call super
		}

		/*
				description?: IStringValue
		 */
		addRolePermList(rolePermList: any, levelAuthority: number): void {
			if (Array.isArray(rolePermList)) {
				for (let i = 0; i < rolePermList.length; i++) this.addRolePermList(rolePermList[i], levelAuthority);
				return;
			}
			const roleName = rolePermList.role;
			if (!roleName) return;
			let r = this._roles[roleName];
			if (!r) r = this._roles[roleName] = {role: roleName, perms: Object.create(null)};
			if ("sortKey" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "sortKey") < levelAuthority) {
					if (!r.sortKey || r.sortKey.level < levelAuthority) {
						r.sortKey = {level: levelAuthority, value: rolePermList.sortKey}
					}
				}
			}
			if ("description" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "description") < levelAuthority) {
					if (!r.description || r.description.level < levelAuthority) {
						r.description = {level: levelAuthority, value: rolePermList.description}
					}
				}
			}
			if ("title" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "title") < levelAuthority) {
					if (!r.title || r.title.level < levelAuthority) {
						r.title = {level: levelAuthority, value: rolePermList.title}
					}
				}
			}
			if ("description" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "description") < levelAuthority) {
					if (!r.description || r.description.level < levelAuthority) {
						r.description = {level: levelAuthority, value: rolePermList.description}
					}
				}
			}
			if ("priority" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "priority") < levelAuthority) {
					if (!r.priority || r.priority.level < levelAuthority) {
						r.priority = {level: levelAuthority, value: rolePermList.priority}
					}
				}
			}
			if ("superRole" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "superRole") < levelAuthority) {
					if (!r.superRole || r.superRole.level < levelAuthority) {
						r.superRole = {level: levelAuthority, value: rolePermList.superRole}
					}
				}
			}
			if ("setPerm" in rolePermList) {
				if (this.parentReg.getRolePropertyLevel(roleName, "setPerm") < levelAuthority) {
					if (!r.setPerm || r.setPerm.level < levelAuthority) {
						r.setPerm = {level: levelAuthority, value: rolePermList.setPerm}
					}
				}
			}
			if (rolePermList.deny) for (let i = 0, l = rolePermList.deny.length; i < l; i++) {
				const permName = rolePermList.deny[i];
				if (this.parentReg.getRolePermLevel(roleName, permName) < levelAuthority) {
					const pre = r.perms[permName];
					if (!pre || pre.level < levelAuthority) {
						r.perms[permName] = {level: levelAuthority, value: false}
					}
				}
			}
			if (rolePermList.allow) for (let i = 0, l = rolePermList.allow.length; i < l; i++) {
				const permName = rolePermList.allow[i];
				if (this.parentReg.getRolePermLevel(roleName, permName) < levelAuthority) {
					const pre = r.perms[permName];
					if (!pre || pre.level < levelAuthority) {
						r.perms[permName] = {level: levelAuthority, value: true}
					}
				}
			}
			if (rolePermList.erase) for (let i = 0, l = rolePermList.erase.length; i < l; i++) {
				const permName = rolePermList.erase[i];
				if (this.parentReg.getRolePermLevel(roleName, permName) < levelAuthority) {
					const pre = r.perms[permName];
					if (!pre || pre.level < levelAuthority) {
						r.perms[permName] = {level: levelAuthority, value: null}
					}
				}
			}
		}

		getPref<P extends REG.JPrefValue>(code: string, defaultValue?: P): P {
			return (code in this._prefs) ? super.getPref(code) : this.parentReg.getPref(code, defaultValue);
		}

		getSvc<T>(code: string, ifNone?: T): T | null | undefined {
			return (code in this._svcs) ? super.getSvc(code, ifNone) : this.parentReg.getSvc(code, ifNone);
		}

		getPrefLevel(code: string): number {
			return (code in this._prefLevels) ? super.getPrefLevel(code) : this.parentReg.getPrefLevel(code);
		}

		getSvcLevel(code: string): number {
			return (code in this._svcs) ? super.getSvcLevel(code) : this.parentReg.getSvcLevel(code);
		}

		getEntryListLevel(codeList: string, codeEntry: string): number {
			const list = this._lists[codeList];
			if (!list) return this.parentReg.getEntryListLevel(codeList, codeEntry);
			const entry = list[codeEntry];
			if (!entry) return this.parentReg.getEntryListLevel(codeList, codeEntry);
			return entry.level;
		}

		/* protected */
		_getListEntries(codeList: string): IList {
			const parentList = this.parentReg._getListEntries(codeList);
			if (!parentList) return this._lists[codeList];
			const list = this._lists[codeList];
			if (!list) return parentList;
			//on fusionne les 2 listes
			const result: Dict<IListEntry> = Object.create(null);
			for (const code in list) {
				const entry = list[code];
				result[entry.code] = entry;
			}
			for (const code in parentList) {
				const entry = parentList[code];
				if (!(entry.code in result)) result[entry.code] = entry;
			}
			return result;
		}

		/* protected */
		_setOverridenSvc(code: string, svcDef: ISvcDef) {
			const subSvcDef = svcDef.override;
			if (subSvcDef) {
				Reg.prototype._setOverridenSvc.apply(this, arguments);
			} else {
				svcDef.svc.setOverridenSvc(this.parentReg.getSvc(code));
			}
		}

		/* protected */
		_getPersistUserData(key: string): Jsonisable | undefined {
			if (this._persistUS) {
				const v = this._persistUS.getUserData(key);
				if (v !== undefined) return v;
			}
			return this.parentReg._getPersistUserData(key);
		}

		hasRoles() {
			for (const i in this._roles) return true;
			return this.parentReg.hasRoles();
		}

		getRoleNames() {
			const roleNames = this.parentReg.getRoleNames();
			for (const i in this._roles) {
				if (roleNames.indexOf(i) == -1) roleNames.push(i);
			}
			return roleNames;
		}

		getRoleProperty(role: string, propName: keyof IRoleValues): string | number | boolean | undefined {
			const r = this._roles[role];
			if (!r) return this.parentReg.getRoleProperty(role, propName);
			const p = r[propName];
			if (!p) return this.parentReg.getRoleProperty(role, propName);
			return p.value;
		}

		getRolePermNames(role: string): string[] | undefined {
			const r = this._roles[role];
			if (!r) return this.parentReg.getRolePermNames(role);
			return r.perms ? Object.keys(r.perms) : [];
		}

		getRolePermAccess(role: string, permName: string): boolean | undefined {
			const r = this._roles[role];
			if (!r) return this.parentReg.getRolePermAccess(role, permName);
			const p = r.perms[permName];
			if (!p) return this.parentReg.getRolePermAccess(role, permName);
			return p.value;
		}

		getRolePropertyLevel(roleName: string, propName: keyof IRoleValues): number {
			const r = this._roles[roleName];
			if (!r) return this.parentReg.getRolePropertyLevel(roleName, propName);
			const p = r[propName];
			if (!p) return this.parentReg.getRolePropertyLevel(roleName, propName);
			return p.level;
		}

		getRolePermLevel(roleName: string, permName: string): number {
			const r = this._roles[roleName];
			if (!r) return this.parentReg.getRolePermLevel(roleName, permName);
			const p = r.perms[permName];
			if (!p) return this.parentReg.getRolePermLevel(roleName, permName);
			return p.level;
		}

		getRegDef(): JRegDef {
			return this.env ? {envKeys: Object.getOwnPropertyNames(this.env), parent: this.parentReg.getRegDef()} : {closed: true, parent: this.parentReg.getRegDef()};
		}
	}

	export type JPrefValue = Jsonisable

	/**
	 * Interface à implémenter pour les svc surchargeant un autre.
	 */
	export interface IOverridingSvc {
		setOverridenSvc(overridenSvc: any): any;
	}


	/**
	 * Level authority minimal, par défaut utilisé dans le code de base.
	 */
	export const LEVELAUTH_CORE = 1;

	/**
	 * Level authority standard utilisé par la config du device (client electron, browser...)
	 */
	export const LEVELAUTH_CLIENT = 11;

	/**
	 * Level authority standard utilisé par la config du portal
	 */
	export const LEVELAUTH_PORTAL = 21;

	/**
	 * Level authority standard utilisé par la config free du portal
	 */
	export const LEVELAUTH_PORTAL_FREE = 31;

	/**
	 * Level authority standard utilisé par la config du modèle
	 */
	export const LEVELAUTH_MODEL = 101;

	/**
	 * Level authority standard utilisé par la config des extensions du modèle
	 */
	export const LEVELAUTH_EXT = 111;

	/**
	 * Level authority standard utilisé par la config des codes free des modèles
	 */
	export const LEVELAUTH_MODEL_FREE = 121;

	/**
	 * Level authority correspondant aux spécifications du user (prefs users)).
	 * Un level supérieur déclaré dans une configuration interdirait la personnalisation de la pref par le user.
	 * TODO : enregistrement centralisé sur serveur associé à ce level.
	 */
	export const LEVELAUTH_USER = 1001;

	/** Level authority dédié aux overrides RTL : logique de surcharge de l'existant => placés à la fin */
	export const LEVELAUTH_RTL_OVR = 2001;

	/** Constante interne. */
	const REDIRECT_TO_SVC = Object.create(null);

	function sortEntries(e1: IListEntry, e2: IListEntry): number {
		return e1.sortKey - e2.sortKey;
	}

	export interface JRegDef {
		envKeys?: string[]
		parent?: JRegDef
		closed?: true
	}

	export interface IListEntry {
		code: string
		codeSvc?: string
		level: number
		value: any
		sortKey: number
	}

	export type IList = { [codeEntry: string]: IListEntry }

	export interface ISvcDef {
		svc: any,
		level: number,
		override?: ISvcDef
	}

	/** Déclaration d'un role avec reg.addRolePermList(). */
	export interface IRoleDef {
		role: string
		title?: string
		description?: string
		priority?: number
		sortKey?: number
		superRole?: string
		allow?: string[]
		deny?: string[]
		erase?: string[]
		/* Optionnel, permission requise pour utiliser ce role (ajout, suppression, ...) */
		setPerm?: string
	}

	export interface IRoleDump extends IRoleDef {
		titleLevel?: number
		descriptionLevel?: number
		priorityLevel?: number
		sortKeyLevel?: number
		superRoleLevel?: number
		setPermLevel?: number
		allowLevels?: number[]
		denyLevels?: number[]
		eraseLevels?: number[]
	}

	export interface IPermNode {
		name: string;
		systemRights: number;
		children?: IPermNode[];
	}

	/** Propriétés des rôles interrogeables. */
	export interface IRoleValues {
		title?: IStringValue
		description?: IStringValue
		priority?: INumberValue
		sortKey?: INumberValue
		superRole?: IStringValue
		setPerm?: IStringValue
	}

	/** Structure interne de stockage. */
	export interface IRoleStruct extends IRoleValues {
		role: string
		perms: { [perm: string]: IBoolValue }
	}

	export interface IStringValue {
		value: string | null
		level: number
	}

	export interface INumberValue {
		value: number | null
		level: number
	}

	export interface IBoolValue {
		value: boolean | null
		level: number
	}


	export class Perm {
		constructor(readonly name: string, readonly parent?: Perm, readonly systemRights: number = 0) {
		}

		toString(): string {return `${this.parent ? this.parent.toString() : ''}/${this.name}`}
	}

	interface ISkinStyleElement extends HTMLStyleElement {
		_styleHolder: SkinHolder;
	}

	export const IS_skinStyleElt = function (n: Node): n is ISkinStyleElement { return n instanceof HTMLStyleElement && '_styleHolder' in n };

	export class SkinHolder implements IOverridingSvc {

		code: string;

		styles: string;

		overridenSkinHolder: SkinHolder;

		protected cache: HTMLStyleElement;

		constructor(code: string, styles: string) {
			this.code = code;
			this.styles = styles;
		}

		setOverridenSvc(skinHolder: SkinHolder) {
			if (this.overridenSkinHolder !== skinHolder) {
				if (this.cache) {
					console.log(`WARNING: SkinHolder already used but not stabilized in extPoints: ${this.styles}`);
					this.cache = null;
				}
				this.overridenSkinHolder = skinHolder;
			}
		}


		getSkinIfExist(root: Document | ShadowRoot): ISkinStyleElement {
			//check si ce style est déjà inclus.
			for (let i = 0; i < root.styleSheets.length; i++) {
				const style = root.styleSheets[i].ownerNode as any;
				if (style._styleHolder === this) return style;
			}
			return null;
		}

		installSkin(root: Document | ShadowRoot = document): HTMLStyleElement {
			//check si ce style est déjà inclus.
			let style = this.getSkinIfExist(root);
			if (style) return style;
			style = this.createStyleElt();
			//sinon, on l'installe
			const stylesRoot = root instanceof Document ? root.head : root;
			if (stylesRoot.firstElementChild && IS_skinStyleElt(stylesRoot.firstElementChild)) {
				let firstNotSkin = stylesRoot.firstElementChild as Element;
				while (firstNotSkin && IS_skinStyleElt(firstNotSkin)) firstNotSkin = firstNotSkin.nextElementSibling;
				stylesRoot.insertBefore(style, firstNotSkin);
			} else {
				stylesRoot.insertBefore(style, stylesRoot.firstChild);
			}
			return style;
		}

		createStyleElt(): ISkinStyleElement {
			if (!this.cache) {
				const styleElt = document.createElement('style');
				if (this.overridenSkinHolder) {
					const body = [this.styles];
					let sub = this.overridenSkinHolder;
					while (sub) {
						body.push(sub.styles);
						sub = sub.overridenSkinHolder;
					}
					styleElt.textContent = body.reverse().join('\n');
				} else {
					styleElt.textContent = this.styles;
				}
				styleElt.dataset.code = this.code;
				this.cache = styleElt;
				//On l'ajoute dans un template du document pour optimisation du clonage des StyleSheets.
				if (!stylesRoot) {
					stylesRoot = document.head.appendChild(document.createElement('template'));
					stylesRoot.setAttribute('id', 'extPointsStyles');
				}
				stylesRoot.content.appendChild(styleElt);
			}
			const styleElt = this.cache.cloneNode(true) as ISkinStyleElement;
			styleElt._styleHolder = this;
			return styleElt;
		}

	}

	let stylesRoot: HTMLTemplateElement;

	export const SKIN_PREFIX = 'skin|';


	/** Registre racine. */
	export let reg: Reg<any> = new Reg();
}


/** Registre courant dans le contexte de la window. */
declare global {
	interface Window {
		reg: IReg<any>;
	}
}
